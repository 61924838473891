import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <BsGithub onClick={() => window.open('https://github.com/qeryz')}/>
        </div>
        <div>
          <BsLinkedin onClick={() => window.open('https://www.linkedin.com/in/marcos-ruben-padilla/')}/>
        </div>
    </div>
  )
}

export default SocialMedia